import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as googleMap from '../functions/googleMap';
import * as templateScripts from '../functions/templateScripts';
import * as adaptHeight from '../functions/adaptHeight';

import * as skrollr from 'skrollr';

document.addEventListener('DOMContentLoaded', function(){

	var $fancybox = $('a.fancybox'),
		$fancyboxThumbs = $('a.fancybox-thumbs'),
		$map = document.getElementById('map'),
		$playVideo = document.querySelector('.play-video'),
		footerHeight = $('footer').height(),
		$mainSlider = document.getElementById('banner'),
		isFirefox = typeof InstallTrigger !== 'undefined',
		isIE = /*@cc_on!@*/false || !!document.documentMode,
		isEdge = !isIE && !!window.StyleMedia;

	templateScripts.init();

	if($mainSlider){
		slickSliders.init();
	}

	if($fancybox.length) {
		$fancybox.fancybox({
			helpers: {
				overlay: {
					locked: false
				}
			}
		});
	}

	if($fancyboxThumbs.length) {
		$fancybox.fancybox({
			afterLoad: function(){
                $('body').addClass('body-fancyboxed');
            },
            beforeClose: function(){
                $('body').removeClass('body-fancyboxed');
            },
			helpers: {
				thumbs: {
					width: 130,
					height: 75
				},
				overlay: {
					locked: false
				}
			}
		});
	}

	if($map) {
		googleMap.init();
	}

	$('.trigger-caption').on('click', function(){
		document.querySelector('.popup-hld').classList.add('js-show');
	});

	$('.apartment-btn').on('click', function(){
		var title = $(this).parent().find('h3').text();

		$('#topic').val(title);
		
		document.querySelector('.popup-hld--apartments').classList.add('js-show');
	});

	if($('.home-menu').length){
		$('#main-nav li a, .menu-footer a').each(function(e){
			$(this).addClass('scroll-to');
		});

		$('#main-nav li:first-child').addClass('current-menu-item');
	}

	$('.close-btn, .popup-blank').on('click', function(){
		document.querySelector('.popup-hld').classList.remove('js-show');
	});

	if($('.dummy-footer').length) {
		$('.dummy-footer').height(footerHeight);
	}

	$('.parallax-number').each(function(i){
		var $self = $(this),
			i = i + 1;

		$self.html('0' + i);
	});

	if(isFirefox){
		$('html').addClass('mozilla');
	}

	if(isIE || isEdge) {
		$('html').addClass('ie');	
	}

	if($('.subpage').length){
		$('.menu-footer a, #main-nav a').each(function(e){
			var titleAttr = $(this).attr('title');

			if(typeof titleAttr !== 'undefined') {
				var $self = $(this),
					homeHref = $('#logo a').attr('href'),
					attrHref = homeHref+titleAttr;

				$self.attr('href', attrHref);
			}
		});
	}

	$('.slidingItem').on('click', function(){
		var $self = $(this),
			dataSlide = $(this).attr('data-slide');

		$('#slide-content-container').find('.close').addClass('js-show');

		if(!$('.slide-content[data-num-content="'+dataSlide+'"]').is(':visible')){
			$('.slide-content').fadeOut(function(){
				setTimeout(function(){
					$('.slide-content[data-num-content="'+dataSlide+'"]').fadeIn();
				}, 500);
			});
		}
	});

	$('#slide-content-container .close').on('click', function(){
		var $self = $(this);

		$self.parent().find('.slide-content').slideUp();
		$self.removeClass('js-show');
	});

	if($('.gallery-big-hld').length) {
		$('#skrollr-body').addClass('has-gallery');
	}

	if($('#section-gallery').length) {
		$('.dummy-footer').remove();
	}
});

function checkViewPort() {
	if($(window).width() > 1024 && $('html').hasClass('mobile-view')){
		$('html').removeClass('mobile-view');
	} else if ($(window).width() <= 1024 && !$('html').hasClass('mobile-view')){
		$('html').addClass('mobile-view');
	}
}

$(window).on('load', function() {
	var $adaptHeight = $('.column-section.adapt-height'),
		$fullscreenView = $('.fullscreen-view'),
		$videobg = document.querySelector('.videobg');

	checkViewPort();
	
	if(!$('html').hasClass('tablet-view') && !$('html').hasClass('mobile-view')){
		var mySkrollr = skrollr.init({
			edgeStrategy: 'set',
			easing: 'cubic',
			smoothScrolling: false,
			forceHeight: false
		});
	}

	if($adaptHeight.length || $fullscreenView.length) {
		adaptHeight.init();
	}

	if($videobg) { 
		$('.videobg').bgVideo();
	}
});

$(window).resize(function() { 
	var $adaptHeight = $('.column-section.adapt-height'),
		$fullscreenView = $('.fullscreen-view');

	if($adaptHeight.length || $fullscreenView.length) {
		adaptHeight.init();
	}

	checkViewPort();

	if($(window).width() > 1023) { $('#menu').removeClass('menu-is-open'); }
});

AOS.init({
	disable: 'mobile',
	easing: 'ease-out',
	duration: 1000
});