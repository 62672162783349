var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );

export function init() {

	(function(){

		var cashSections = [];

		function backToTop() {
			if ($(window).scrollTop() > $(window).height()) {
				$( '#gototop' ).addClass("visible");	
			} else { 
				$( '#gototop' ).removeClass("visible");	
			}
		}

		// reorganize isotope
		function reorganizeIsotope() { 
			$('.isotope-grid[class*="style-modern"]').each(function(){
				var $container = $(this);
				var width = $container.find(".grid-sizer").width();
				var ratioheight = $container.data('heightratio');
				if (!ratioheight) { ratioheight = 0.8; }
				var spacing = 0; if ($container.hasClass("isotope-spaced") || $container.hasClass("isotope-spaced-mini")) { spacing = parseInt($container.find(".isotope-item").css('marginRight'),10); }
				var height = parseInt(width * ratioheight, 10);
				$container.children('.isotope-item').css({ 'height': height+'px' });
				$container.children('.isotope-item.tall, .isotope-item.wide-tall').css({ 'height': height*2+spacing+'px' });
				$container.isotope('layout');

				// adapt images
				$container.children('.isotope-item').each(function() {
					var imgHeight = $(this).find("img").height();
		           	var imgWidth = $(this).find("img").width();
					var imgRatio = imgWidth/imgHeight;
					var itemHeight = $(this).height();
		           	var itemWidth = $(this).width();
					var itemRatio = itemWidth/itemHeight;
					var imgClass = '';
					if (imgRatio < itemRatio) { imgClass = 'wide-img'; $(this).find("img").removeClass('tall-img'); }
					else { imgClass = 'tall-img'; $(this).find("img").removeClass('wide-img'); }
					$(this).find("img").addClass(imgClass);
		        });
			});
		}

		$(window).load(function() {
		
			// Isotope
			if( $('.isotope-grid').length ) {
				$('.isotope-grid').each(function(){
					var $container = $(this);
					$(this).prepend('<div class="grid-sizer"></div>');
					if (!$container.hasClass("fitrows")) { 
						$container.isotope({
							layoutMode: 'masonry',
							itemSelector : '.isotope-item',
							masonry: { columnWidth: '.grid-sizer' }
						});	
					} else {
						$container.isotope({
							layoutMode: 'fitRows',
							itemSelector : '.isotope-item',
							masonry: { columnWidth: '.grid-sizer' }
						});	
					}
				});
					
				$('.filter').on("click", "li a", function() { 
					var thisItem = $(this),
						parentul = thisItem.parents('ul.filter').data('related-grid'),
						dataCategory = thisItem.attr('data-cat');
					
					if (!parentul) {
						alert('Please specify the data-related-grid');
					} else {
						thisItem.parents('ul.filter').find('li').removeClass('active');
						thisItem.parent('li').addClass('active');
						var selector = thisItem.attr('data-filter');
						$('#'+parentul).isotope({ filter: selector }, function(){ });
						var elems = $('#'+parentul).isotope('getFilteredItemElements');
						var i;

						$('.isotope-item').find('.fancybox').removeAttr('data-fancybox-group');
						$('.isotope-item').removeClass('is-filtered');

						for(i=0;i<elems.length;i++){
							elems[i].classList.add('is-filtered');
						}
						
						$('.is-filtered').find('.fancybox').attr('data-fancybox-group', dataCategory);
					}
					
					$('.smoothscrollDiv').css('display','none');

					return false;
				});
				
				reorganizeIsotope();

				if($('.isotope-grid').length) {
					setTimeout(function(){
						$('.isotope-grid').isotope('layout');
					}, 0);
				}
			}
			
			$("body").addClass("page-is-loaded"); // hide pageloader
			
			// Prepare adapt height
			$('.column-section.adapt-height').each(function() {
				$(this).children('.column').each(function() {
					if (!$.trim($(this).html())) {
						$(this).addClass("empty-content");
					} else {
						if ($(this).children('.col-content').length < 1) {  $(this).wrapInner('<div class="col-content"></div>'); }	
					}
				});
			});
			
			// open & close search
			$('#show-search').on("click", function() { $(".header-search-content").addClass("search-visible"); return false; });
			$('.header-search-content .search-outer').on("click", function() { $(".header-search-content").removeClass("search-visible"); return false; });
			$('#close-search').on("click", function() { $(".header-search-content").removeClass("search-visible"); return false; });

			// Add gap for submenu if too close to border)
			$('nav#main-nav > ul > li:last-child').prev('li').andSelf().each(function() {
		        if ($(this).children('ul.submenu').length > 0) {
					var pageRight = parseInt($(window).width() - ($("#page-container").offset().left + $("#page-container").outerWidth()), 10);
					var elRight = parseInt( ($(window).width() - ($(this).offset().left + $(this).outerWidth())) - pageRight, 10);
					if (elRight < 150) { $(this).children('ul.submenu').addClass('add-gap'); }	
				}
		    });
			
			// Responsive Navigation
			$('header').on("click", ".mobile-nav-toggle", function() { 
				$('#menu').toggleClass('menu-is-open'); 
				return false;
			});
			
			$('#main-nav').on("click", "li > a", function() {
				var thisItem = $(this); 
				var thisParent = $(this).parent('li'); 
				if(thisItem.siblings('ul').length > 0 && thisItem.siblings('ul').css('display') === 'none') {
					thisItem.siblings('ul').slideDown(400);
					thisParent.siblings('li').children('ul').slideUp(400);
					return false;	
				}
			});
			
			// Show language
			$('header').on("click", ".show-language", function() { 
				$('#header-language > .header-language-content').toggleClass('show'); 
				return false;
			});
			
			// Scroll to
			$('body').on('click', '#gototop,#scroll-down,.scroll-to', function() {
				var topPos = 0;
				if ($(this).attr("id") === "scroll-down") { topPos = $("#page-body").offset().top; }
				if ($(this).hasClass("scroll-to")) { 
					var href = $(this).attr('href');
					var target = $(this).attr('data-target');
					if (href.charAt(0) === '#') { target = href; }
					topPos = $(target).offset().top - $('#header').height();
				}
				$('html,body').animate({ scrollTop: topPos}, 1000, 'easeInOutQuart');

				if($('#menu').hasClass('menu-is-open')){
					$('#menu').removeClass('menu-is-open');
				}
				
				return false;
			});		
			
			// Tabs
			$(".tabs").each(function() {
				var thisItem = $(this); 
				thisItem.find('.tab-content').removeClass('active');
				var rel = thisItem.find('.active a').attr('href');
				thisItem.find('.'+rel).addClass('active');
			});
			
			$(".tab-nav").on("click", "a", function() { 
				var thisItem = $(this); 
				var parentdiv = thisItem.parents('li').parent('ul').parent('div');
				var rel = thisItem.attr('href');
				
				$(parentdiv).find(".tab-nav li").removeClass("active");
				thisItem.parents('li').addClass("active");
				
				$(parentdiv).find(".tab-container .tab-content").hide().removeClass('active');
				$(parentdiv).find(".tab-container ."+rel).fadeIn(500).addClass('active');
				
				return false;
			});
			
			// Toggle accordion	
			$(".toggle-item").each(function() {
				$(this).find('.toggle-active').siblings('.toggle-inner').slideDown(300);							
			});
			
			$(".toggle-item").on("click", ".toggle-title", function() { 
				var thisItem = $(this); 
				var parentdiv = thisItem.parent('div').parent('div');
				var active = thisItem.parent('div').find('.toggle-inner').css('display');
				
				if ($(parentdiv).attr('class') === 'accordion') {
					if (active !== 'none' ) { 
						$(parentdiv).find('.toggle-item .toggle-inner').slideUp(300);
						thisItem.toggleClass('toggle-active');
					} else {
						$(parentdiv).find('.toggle-item .toggle-inner').slideUp(300);
						$(parentdiv).find('.toggle-item .toggle-title').removeClass('toggle-active');
						
						thisItem.toggleClass('toggle-active');
						thisItem.siblings('.toggle-inner').slideDown(300);
					}
				} else {
					thisItem.toggleClass('toggle-active');
					thisItem.siblings('.toggle-inner').slideToggle(300);
				}
				
				return false;
			});

			$('.smart-nav-section').each(function() {
				cashSections.push({id: $(this).attr("id"), position: $(this).offset().top-150});
		    });

			backToTop();
		});


		$(window).scroll(function() {
			var webHeight = $(window).height();
			 
			backToTop();

			if($('.column-fixed').length) {
				if($(window).scrollTop() > 10) {
					$('.column-fixed').addClass('one-half addFixed');
				}
				
				if($(window).scrollTop() + $(window).height()+100 >= $(document).height()) {
			       $('.column-fixed').addClass('addAbsolute');
			   	} else {
			   		$('.column-fixed').removeClass('addAbsolute');
			   	}
			}

			$.each(cashSections, function(index,value) {
				var nextIndex = index+1;
				var linkedAnchor = $('a.scroll-to[href="#'+value.id+'"]').parent('li');

				if (cashSections.length !== nextIndex && $(window).scrollTop() > value.position && $(window).scrollTop() < cashSections[nextIndex].position) {
					if (!linkedAnchor.hasClass('current-menu-item')) {
						linkedAnchor.siblings("li").removeClass('current-menu-item');
						linkedAnchor.addClass('current-menu-item');
					}
				} else if (cashSections.length === nextIndex && $(window).scrollTop() > value.position) {
					if (!linkedAnchor.hasClass('current-menu-item')) {
						linkedAnchor.siblings("li").removeClass('current-menu-item');
						linkedAnchor.addClass('current-menu-item');
					}
				}
			});
		});

		$(window).resize(function() { 
			if( $('.isotope-grid').length ) { reorganizeIsotope(); }
		});

	})($);
	
}